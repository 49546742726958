<!-- 客服 -->
<template>
  <div class="kf-page">
    <van-loading size="24px" v-if="!urlLink" style="padding-top: 40px"
      >{{ $i18n.t("cus_linking") }}...</van-loading
    >
    <iframe
      v-else
      style="width: 100%; height: 100%"
      :src="urlLink"
      frameborder="0"
      scrolling="no"
      id="bdIframe"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlLink: "",
    };
  },
  created() {
    this.getCusAddress();
  },
  methods: {
    // 获取客服链接
    async getCusAddress() {
      const res = await this.$http.getCusAddress();
      if (res.code === 0) {
        this.urlLink = res.data;
      }
    },
    closeKf() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less">
.kf-page {
  height: 100vh;
  background: #f5f5f5;
}
</style>
